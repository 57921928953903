<script setup lang="ts">
import { machineryInsuranceDeductible, machineryPaymentConditions, machineryRubrics } from '~/server/schemas'
import type { MachineryCategories, UpdateOrCreateMachinery } from '~/types'

const { openMachineryCreateOrEditPopup: { data: popupData, close: closePopup_ } } = useGlobalOpeners()

const { key: localStorageKey, customKeyInfix, clearFormStorage } = useLocalStorageKeyForFormKit()

function closePopup() {
  clearFormStorage()
  closePopup_()
}

const { machinery: queryMachinery, machineryType: queryMachineryType, machineryDrive: queryMachineryDrives, customer } = useQuery()

const id = computed(() => {
  if (!popupData.value) {
    return
  }

  if (popupData.value.mode === 'update') {
    return popupData.value.id
  }
})

const { data: machinery } = queryMachinery.byId(id, undefined, undefined, popupData.value?.isForeignMachinery)
const { data: machineryTypes } = queryMachineryType.all()
const { data: machineryDrives } = queryMachineryDrives.all()
const { data: customers } = customer.all(undefined, { enabled: popupData.value?.isForeignMachinery })

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const update = useMutation({
  mutationFn: $trpc.machinery.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Maschine konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['machines'] })
    closePopup()
    notification.success({ title: 'Die Maschine wurde erfolgreich aktualisiert', duration: 4500 })
  },
})
const create = useMutation({
  mutationFn: $trpc.machinery.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Maschine konnte nicht erstellt werden' }),
  onSuccess: async (machinery) => {
    await queryClient.invalidateQueries({ queryKey: ['machines'] })
    closePopup()
    notification.success({ title: `Die Maschine wurde erfolgreich erstellt: ${machinery.id}`, duration: 4500 })
  },
})

function saveCreateOrEditForm(payload: UpdateOrCreateMachinery) {
  if (payload.mode === 'create') {
    return create.mutateAsync(payload.data)
  } else {
    return update.mutateAsync(payload.data)
  }
}

const payload = ref<UpdateOrCreateMachinery | undefined>()

watch(
  () => ({ popupDataValue: popupData.value ?? null, machineryValue: machinery.value ?? null }),
  ({ popupDataValue, machineryValue }) => {
    // Don't override existing `payload` if already set
    if (payload.value) {
      return
    }

    if (!popupDataValue) {
      return
    }

    if (popupDataValue.mode === 'create') {
      customKeyInfix.value = popupDataValue.category

      payload.value = {
        mode: 'create',
        data: {
          id: undefined,
          category: popupDataValue.category ?? 'forklift',
          deliveryCompanyName: '',
          producerCompanyName: '',
          typeId: '',
          machineryRubric: machineryRubrics.options[0],
          driveId: '',
          yearBuilt: 2024,
          pricePurchaseEuros: 0,
          paymentCondition: machineryPaymentConditions.options[0],
          liftingHeightInMillimeters: null,
          liftingWeight: 0,
          forkLengthInMillimeters: null,
          workHeightInMillimeters: null,
          storageLocation: '',
          deliveryDate: null,
          comment: '',
          isReleasedForRent: false,
          isReleasedForSale: false,
          isForeignMachinery: popupDataValue.isForeignMachinery ?? false,
          purchasing: null,
          additionalDeliveryCost: 0,
          documentFiles: [],
          marketingPhotos: [],
          marketingVideos: [],
          externalMarketingVideos: [],
          transportFiles: [],
          documentUVVFiles: [],
          documentCEFiles: [],
          documentCircuitDiagramsFiles: [],
          documentSparePartsCatalogFiles: [],
          documentWeightTest: [],
          specialPermissions: [],
          insuranceDeductible: machineryInsuranceDeductible.options[0],
          priceRecommendedToSellFor: undefined,
          priceHanselmannPubliclySellsFor: undefined,
        },
      }
      return
    }

    if (popupDataValue.mode === 'update' && machineryValue) {
      customKeyInfix.value = machineryValue.id

      payload.value = {
        mode: 'update',
        data: machineryValue,
      } as UpdateOrCreateMachinery
    }
  },
  {
    immediate: true,
  },
)

const { openMachineryTypePopup } = useGlobalOpeners()

function openMachineryType(category: MachineryCategories) {
  openMachineryTypePopup({ editMachineryTypeMode: 'create', machineryCategory: category, isCreatedMachineryTypeIdRequired: true })
}
</script>

<template>
  <ThePopup v-if="popupData && payload" :show="Boolean(payload)" title="Maschinen-Anlage" @close="closePopup">
    <MachineryCreationCreateOrEditForm
      v-model:payload="payload"
      :machinery-types="machineryTypes ?? []"
      :created-type-id="popupData.typeId"
      :machinery-drives="machineryDrives ?? []"
      :customers="customers ?? []"
      :local-storage-key="localStorageKey"
      @save="saveCreateOrEditForm"
      @open-machinery-type-popup="openMachineryType"
    />
  </ThePopup>
</template>
